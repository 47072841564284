
html {
  font-size: 62.5%;
  line-height: 1;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  
  // Variables
  --base-spacing: 40px;
}
/* 
Breakpoints 
Since responsive design is a primary goal of this layout, various breakpoints are used to define layout styles. These breakpoints are defined here and reused throughout the stylesheets. Additional values can be added or these can be changed but doing so may have unintended consequences for different layouts throughout the site.
*/

$bp-xsmall: 600px;
$bp-small: 780px;
$bp-med: 1060px;
$bp-large: 1400px;
/* 
Media Queries
This one is used frequently to add breakpoints for responsive styling. It is set up using the "min-width" property so any value placed inside the mixin will be effective when the viewport width is met or exceeded. This means that when creating styles, be sure to have the base styles be whatever layout is desired for the smallest viewport and then add styles within the mixins at various widths to build out the layout for wider screen sizes. This corresponds to the "mobile first" philosophy of styling.
*/
@mixin respond-at($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

/* Example of Media Query Mixin:
 @include respond-at($bp-small) {
    width: 250px;
}
*/

@mixin respond-at-height($media) {
  @media only screen and (min-height: $media) {
    @content;
  }
}

/* Example of Vertical Media Query Mixin:
 @include respond-at-height($bp-vert-small) {
    width: 250px;
}
*/

/* This media query is designed for instances when a desired effect is necessary only in a mobile context and otherwise the default settings should apply. An example is using a button at a small screen size. Outside of the small size, the button settings would be fine, but at the small screen size the padding, margin, or other settings might need to be smaller to accomodate the sizing.
*/

@mixin respond-at-max-height($media) {
  @media only screen and (max-height: $media) {
    @content;
  }
}

/* Example of Vertical Media Query Mixin:
 @include respond-at-height($bp-vert-small) {
    width: 250px;
}
*/

$primary-color: #08789b;
$primary-light: #8bc9db;
$primary-dark: #0e4759;
$alert: #ff0f0f;
$alert-light: #fd7f7f;
$alert-dark: #8d0909;
$alert-green: #3dbc12;
$alert-green-dark: #2a8e08;

hr {
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.75),
    transparent
  );
  height: 1px;
  border: 0;
  margin: 0;
}

@import "./partials/base/reset";
@import "./partials/base/typography";
@import "./partials/shared/utility";
@import "./partials/leaflet";
@import "./partials/shared/layout";

.leaflet-container {
  width: 100vw;
  height: 100vh;
}

// main {
//   width: calc(100% - 50px);
// }

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 0.7em;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);

  & a {
    color: inherit;
  }
}

@import "./partials/map/map";
@import "./partials/sidebar/sidebar";
@import "./partials/shared/shared";
@import "./partials/login/login";
@import "./partials/ui/nprogress";
