@import "./sidebar-expander";
@import "./sidebar-main-button";
@import "./sidebar-sub-button";
@import "./sidebar-sub-link";
@import "./sidebar-mapviews";

.sidebar__container {
  width: 40px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: width 0.5s;
  box-shadow: 0 0 15px black;
  z-index: 500;
  height: 100vh;
  background-color: $primary-color;
  color: white;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.1);

  &--open {
    width: 185px;

    @include respond-at($bp-small) {
      width: 260px;
    }

    @include respond-at($bp-med) {
      width: 310px;
    }
  }
}
.sidebar__sub-container {
  width: 175px;

  @include respond-at($bp-small) {
    width: 250px;
  }

  @include respond-at($bp-med) {
    width: 300px;
  }
}
