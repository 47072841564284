.rotate-45-cw {
  transform: rotate(45deg);
  transition: transform 300ms ease-in;
}

.rotate-45-ccw {
  transform: rotate(-45deg);
  transition: transform 300ms ease-in;
}

.rotate-90-cw {
  transform: rotate(90deg);
  transition: transform 300ms ease-in;
}

.rotate-90-ccw {
  transform: rotate(-90deg);
  transition: transform 300ms ease-in;
}

.rotate-180-cw {
  transform: rotate(180deg);
  transition: transform 300ms ease-in;
}

.rotate-180-ccw {
  transform: rotate(-180deg);
  transition: transform 300ms ease-in;
}

.center-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100vw;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}

.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}

.transition-item {
  transition: transform 300ms ease-in;
}
