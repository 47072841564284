.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.width-100 {
  width: 100%;
}

.width-75 {
  width: 75%;
}

.width-50 {
  width: 50%;
}
