.button__general-button {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid $primary-light;
  font-size: 12px;
  border-radius: 10px;
  background: $primary-light;
  font-weight: 400;
  flex: 1 1 auto;
  text-align: center;
  display: block;
  width: 45%;
  color: $primary-dark;
  cursor: pointer;

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-dark;
  }

  &:active {
    background-color: $primary-dark;
  }

  &:disabled {
    background-color: rgb(238, 238, 238);
    border-color: rgb(204, 204, 204);
    color: rgb(204, 204, 204);
  }
}

.link-button {
  text-decoration: none;
  cursor: pointer;
}

svg.button__close-button-icon,
.button__close-button-icon {
  cursor: pointer;
  color: white;
  &:hover {
    color: $primary-dark;
  }
  
  &:active {
    color: green;
  }
  svg {
    height: 1.5rem;
  }
}

svg.button__close-button-icon {
  height: 1.5rem;
}

.button__alert {
  border: 1px solid $alert;
  background: $alert-light;
  color: inherit;

  &:hover {
    background-color: $alert-dark;
    border: 1px solid $alert;
  }

  &:active {
    background-color: $alert-dark;
  }
}

.button__success {
  border: 1px solid $primary-light;
  background: $alert-green;
  color: inherit;

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-dark;
  }

  &:active {
    background-color: $primary-dark;
  }
}

.button__text-button {
  border: 0;
  background-color: inherit;
  color: inherit;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    height: 1.5rem;
  }
}
