.sidebar-sub-link__main-container {
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  margin: 5px 0 0 40px;
  padding: 5px 0;
  background-color: transparent;
  align-items: center;
  color: white;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: hsla(0, 0%, 100%, 0.2);
  }
}
a.sidebar-sub-link__main-container {
  text-decoration: none;
}

.sidebar-sub-link__left-container {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
}

.sidebar-sub-link__left-icon-container {
  display: flex;
  padding: 0 5px;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.sidebar-sub-link__title {
  display: flex;
  padding: 0 5px;
  height: 100%;
  align-items: center;
  text-decoration: none;

  text-transform: capitalize;
}

.sidebar-sub-link__right-icon-container {
  display: flex;
  padding: 0 5px;
  height: 100%;
  align-items: center;
  background-color: transparent;
  color: white;
}
