.logo__main-container {
  overflow-x: hidden;
  display: flex;
  padding: 5px 0;
  background-color: transparent;
  align-items: center;
}
.logo__icon-container {
  display: flex;
  padding: 0 5px;
  width: 40px;
  align-items: center;
  justify-content: center;

  svg {
    width: 40px;
  }
}
.logo__title-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
