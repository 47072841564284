.point-view__main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  margin: 0 15px;
}

.point-view__section-container {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
}

.point-view__section-container--column {
  flex: 1 1 auto;
  width: 45%;
  &:first-of-type {
    margin-right: 5px;
  }
  &:last-of-type {
    margin-left: 5px;
  }
}

.point-view__section-heading {
  width: 100%;
  border-bottom: 1px solid white;
  font-family: "Lobster", cursive;
  letter-spacing: 0.05em;
  margin-bottom: 5px;
}

.sidebar__close-button-container {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
}
