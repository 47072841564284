.sidebar-main-button__main-container {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  background-color: transparent;
  align-items: center;
  color: white;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: hsla(0, 0%, 100%, 0.2);
  }

  &--callout-button {
    background-color: rgba($color: #ffffff, $alpha: 0.2);

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

a.sidebar-main-button__main-container {
  text-decoration: none;
}
.sidebar-main-button__title-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.sidebar-main-button__left-icon-container {
  display: flex;
  padding: 0 5px;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.5rem;
  }
}

.sidebar-main-button__title {
  display: flex;
  padding: 0 5px;
  height: 100%;
  align-items: center;
}

.sidebar-main-button__right-icon-container {
  display: flex;
  padding: 0 5px;
  height: 100%;
  align-items: center;
  transition: transform 300ms ease-in;

  svg {
    height: 1.5rem;
  }
}
