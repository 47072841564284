.sidebar-sub-button__main-container {
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  margin: 5px 0 0 40px;
  background-color: transparent;
  align-items: center;
  color: white;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 0;

  }


  &--callout-button {
    background-color: rgba($color: #ffffff, $alpha: 0.2);

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

a.sidebar-sub-button__primary-button {
  text-decoration: none;
}
.sidebar-sub-button__primary-button {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 5px 0;
  flex: 1 1 auto;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    background-color: hsla(0, 0%, 100%, 0.2);
  }
}

.sidebar-sub-button__left-icon-container {
  display: flex;
  padding: 0 5px;
  width: 40px;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  svg {
    height: 1.5rem;
  }
}

.sidebar-sub-button__title {
  display: flex;
  padding: 0 5px;
  height: 100%;
  align-items: center;
  text-decoration: none;

  @media (max-width: 1060px) {
   text-align: left; 
  }

  @media (max-width: 768px) {
   text-align: left; 
   padding: 0;
  }
}

.sidebar-sub-button__secondary-button {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 5px;
  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.2);
    color: red;
  }

  &:active {
    background-color: hsla(0, 0%, 100%, 0.2);
    color: red;
  }

  svg {
    height: 1.5rem;
  }
}
