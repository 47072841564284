.login__container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../../images/map-background.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $primary-color;
  border-radius: 10px;
  padding: 15px;
  color: white;
  width: 90%;
  @include respond-at($bp-xsmall) {
    width: 70%;
  }
  @include respond-at($bp-small) {
    width: 50%;
  }
  @include respond-at($bp-med) {
    width: 40%;
  }
  @include respond-at($bp-large) {
    width: 30%;
  }
}

.login__login-form {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 15px;
}

.login__form-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.login__login-form-field {
  margin-bottom: 10px;
}
.login__login-form-button-container {
  display: flex;
  justify-content: flex-end;
}

.login__login-form-button {
  margin: 10px;
  padding: 10px;
  &:last-of-type {
    margin-right: 0;
  }
}

.login__login-form-text-button {
  width: 50%;
}
