body {
  font-family: "Raleway", sans-serif; // weights: 300, 400, 600, 800
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;

  }
}


h1,
.text-xlarge {
  font-weight: 100;
  font-size: clamp(1.8rem, 2.3vw, 2.6rem);
  font-family: "Lobster", cursive;
  letter-spacing: 0.05em;
  margin: 0;
}

h2,
.text-large {
  color: #fff;
  font-weight: 100;
  font-size: clamp(1.5rem, 2.3vw, 2rem);
  // border-bottom: $primary-color solid 1px;
  font-family: "Lobster", cursive;
  letter-spacing: 0.05em;
}

h3,
.text-med {
  font-weight: 400;
  font-size: clamp(1.5rem, 2.3vw, 2rem);
}

h4 {
  font-family: "Lobster", cursive;
  letter-spacing: 0.1em;
  border-bottom: 1px solid $primary-color;
}

p {
  line-height: 1.5;
  letter-spacing: 0.05em;
}

a {
  color: inherit;
}

.text-medium-small {
  font-size: clamp(1rem, 2.3vw, 1.4rem);
}

.text-small {
  font-size: clamp(1rem, 2.3vw, 1.2rem);
}

.text-smallest {
  font-size: clamp(0.8rem, 2.3vw, 1rem);
}

.text-center {
  text-align: center;
}

ul.infoList {
  letter-spacing: 0.1em;
}
.smallText {
  font-size: 10;
}
