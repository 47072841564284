.leaflet-popup-content-wrapper {
  background-color: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-popup-content {
  display: flex;
  flex-direction: column;
}

.marker-popup__marker-header-text {
  color: black;
  margin: 5px 0;
}

.marker-popup__marker-description-text {
  margin: 5px 0;
}

.marker-popup__marker-buttons-container {
  display: flex;
}

.marker-popup__marker-button-link {
  flex: 1 1 auto;
  text-align: center;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid $primary-dark;
  font-size: 12px;
  border-radius: 10px;
  background: $primary-color;
  font-weight: 400;
  color: white;
  display: block;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    background-color: $primary-color;
    border: 1px solid $primary-dark;
    color: white;
  }

  &:active {
    background-color: $primary-dark;
    color: white;
  }
}


img.leaflet-marker-icon[src="/mapIcons/dotIcon.svg"] {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(8, 120, 155, 1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 rgba(8, 120, 155, 0.7);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgba(8, 120, 155, 0);
  }
  
  100% {
    
    box-shadow: 0 0 0 0 rgba(8, 120, 155, 0);
  }
}