.overlay__background-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #3f3f3f, $alpha: 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.overlay__main-container {
  background-color: white;
  padding: 25px;
  color: black;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
}

.overlay__close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
