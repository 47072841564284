.form__section-container {
  display: flex;
  flex-direction: column;
  padding: 15px;

  &--side-by-side {
    flex-direction: row;
    align-items: center;
  }
}

.form__form-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;

  }
}

.form-item__required {
  &::after {
    content: "*";
    color: red;
  }
}

.form__link-group-container {
  display: flex;
  margin-left: 15px;

  @media (max-width: 1060px) {
    margin-left: 0;
  }
}

.form__link-group {
  flex: 1 1 auto;

  @media (max-width: 1060px) {
    width: 98%;
  }
}

.form__link-remove-button {
  display: flex;
  align-items: center;
  padding: 10px;
}

.form__rating-stars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__rating-stars-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

.form__rating-stars-item {
  display: flex;
  align-items: center;
  padding: 7px 4px;
  font-size: 15px;
  width: 23px;
  height: 30px;

  &:hover {
    color: black;
  }

  &:hover ~ & {
    color: black;
  }

  &--clear {
    width: auto;
    font-size: 12px;
    margin-right: 10px;
  }

  svg {
    height: 1.5rem;
  }
}
