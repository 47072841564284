/*
Defines the styles for the loader bar displayed when api actions are in process

The majority of these styles are the default for the nProgress component used
*/

// The container in the React component used to contain the progress bar. It is set to `position: fixed` to ensure the bar always displays at the top of the viewport so no matter where on the screen the user is, they will see the bar
#loaderBar {
  width: 100%;
  // height: 10px;
  overflow: visible;
  position: fixed;
  z-index: 1000;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  // Sets the bar to the primary red color used throughout the interface
  background: $primary-dark;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 5px;
}

/* Fancy blur effect */
// #nprogress .peg {
//   display: block;
//   position: absolute;
//   right: 0px;
//   width: 100px;
//   height: 100%;
//   box-shadow: 0 0 10px $color-alert, 0 0 5px $color-alert;
//   opacity: 1;
//   transform: rotate(3deg) translate(0px, -4px);
// }

/* Remove these to get rid of the spinner */
// #nprogress .spinner {
//   display: block;
//   position: fixed;
//   z-index: 1031;
//   top: 15px;
//   right: 15px;
// }

// #nprogress .spinner-icon {
//   width: 18px;
//   height: 18px;
//   box-sizing: border-box;

//   border: solid 2px transparent;
//   border-top-color: $color-alert;
//   border-left-color: $color-alert;
//   border-radius: 50%;
//   animation: nprogress-spinner 400ms linear infinite;
// }
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
