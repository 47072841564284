.mapviews__new-mapview-container {
  margin-left: 40px;
}

.mapviews__new-mapview-form-field-container {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  align-items: center;
}

.mapviews__new-mapview-form-label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.mapviews__new-mapview-form-field {
  width: 75%;
  margin: 0 5px 5px 5px;
}

.mapviews__new-mapview-save-button {
  padding: 5px;
}
