.sidebar-expander__main-container {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  display: none;
}

.sidebar-expander__main-container--expanded {
  max-height: 5000px;
  transition: max-height 0.5s;
  display: block;
}
